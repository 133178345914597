/* You can add global styles to this file, and also import other style files */

// Bootstrap and app specifc variables
@import 'variables';
@import 'common.scss';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Grand+Hotel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&family=Ubuntu:wght@300;400;500;700&display=swap');
html {
  width: 100%;
  overflow-x: hidden;
}
body {
  font-family: 'Poppins', sans-serif;
  background-color: #fafbfd;
}
p {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #3f3d56;
  opacity: 0.8;
}

.slider {
  width: 100% !important;
}
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 8px;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:active,
body::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 10px;
}
input[type='email'] {
  text-transform: lowercase;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.Cources__list .tooltip-inner {
  // width: 390px !important;
  // height: 235px;
  left: 3866px;
  top: 787px;
  padding: 15px;

  /* #FFFFFF */

  background: #ffffff;
  color: black;
  box-shadow: 0px 4px 26px rgba(40, 58, 73, 0.25);
  border-radius: 16px;
 .tooltip_h3{
  font-size: 12px;
  margin-bottom: 0px;
  }
  .tooltip_h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #211f3c;
  }
  .tooltip_p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #211f3c;
  }
  .pading_for_mail {
    padding-top: 2px;
    padding-left: 7px;
    max-width:150px;
    text-align: initial;
  }
}
.w_33{
  width:33%;
}
.Cources__list .bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: white;
}
.Cources__list .bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: white;
}
.Cources__list .bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0;
  border-right-color: white;
}
.Cources__list .bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: white;
}

.Cources__list .tooltip.in {
  opacity: 1 !important;
  filter: alpha(opacity=100);
}
.Cources__list .tooltip.show {
  opacity: 1 !important;
}
.Cources__des_con__title .tooltip-inner{
  width: 100% !important;
  height: 20px !important;
  background-color: black;
}
.rightSection::-webkit-scrollbar,
.rightSection::-webkit-scrollbar {
  width: 8px;
}

.rightSection::-webkit-scrollbar-track,
.rightSection::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 10px;
}

.rightSection::-webkit-scrollbar-thumb,
.rightSection::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

.rightSection::-webkit-scrollbar-thumb:hover,
.rightSection::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 10px;
}

.rightSection::-webkit-scrollbar-thumb:active,
.rightSection::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 10px;
}

.spinner-careerOption-filter {
  div {
    div:first-child {
      top: 300px !important;
    }
  }
}