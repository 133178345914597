$fontFamilyGrandHotel: 'Grand Hotel', cursive;
$ubuntu: 'Ubuntu', sans-serif;
$poppins: 'Poppins', sans-serif;
$GochiHand: 'Gochi Hand';
h1,
h2,
h3,
h4 {
  font-family: 'Ubuntu', sans-serif;
}

$yiq-contrasted-threshold: 160;

/* app specific variables */
$navyBlue: #c4f0f9;
$gray: #e0e0de;
$red: red;
$white: #fff;
$yellow_0: yellow;
$lightGrayc8: #c8c7cd;
$whitef5: #f5f9fa;
$grey2: #aaa;
$gray63: #636377;
$blue65: #65dbf4;
$gray8c: #8c8b9a;
$bluec2: #c2f1f9;
$orange: #ffe600;
$skyblue: #b7e0e0;
$yellow: #e7cb2d;

$brown: #93626c;
$yiq-contrasted-threshold: 160;
$white: #ffffff;
$white2: #dbdbdb;
$white3: #fff;
$white4: #ddd;
$white5: #f7f6f6;
$white6: #dfdfdf;
$white7: #ebece9;
$blue: #21cbef;
$blue2: #5ec2f0;
$blue3: #bfc5f5;
$blue4: #b2fce7;
$blue5: #5364ff52;
$blue6: #4242ff;
$blue7: #4144dd;
$skyblue: #dcf5f6;
$skyblue2: #ccf4eb;
$skyblue3: #339dff;
$skyblue4: #2980b9;
$skyblue5: #3fccad;
$skyblue6: #b6e9e9;
$skyblue7: #cadee6;
$skyblue8: #98e6d0;
$skyblue9: #c4f8f8;
$skyblue10: #6decc2;
$skyblue11: #99c8f5;
$skyblue12: #eee;

/*primary colors*/
$cf_yellow: #febd21;
$cf_yellow2: #f8c51d;
$orange: orange;
$orange2: #ecbe40;
$orange3: #f39033;
$orange4: #fcc654;
$lightOrange:#FBEFD1;
$cf_blue: #20cbf0;

/*seconday colors*/
$cf_yellow1: #ff9d38;
$cf_yellow2: #febd21;
$cf_yellow3: #fee161;
$cf_yellow4: #fbebb1;
$cf_yellow5: #fceabb;
$cf_yellow6: #f3e39b;
$cf_yellow7: #fef200;
$cf_lightGreen: #63dfd9;
$cf_lightGreen2: #64dfab;
$cf_lightBlue: #c3f1fa;
$cf_red1: #ff4e34;
$cf_red2: #ff4b64;
$cf_red3: #ff7492;
$cf_red4: #f54d4d;
$cd_red5: #fabfcc;
$cf_red6: #fbe0e8;
$cf_red7: #fc3a3a;
$cf_red8: #ff3c00;
$cf_red9: #ee2d1f;
$rose: #f557a1;
$rose2: #ffb3b3;
$cf_green1: #46cb5b;
$cf_green2: #15d7a1;
$cf_green3: #28be3f;
$cf_green4:#E7FBF5;
$cf_darkBlue: #2f45fd;
$cf_darkBlue2: #5867e7;
$cf_dardBlue6: #7171ee;
$cf_darkBlue3: #211f3b;
$cf_darkBlue4: #2433b9;
$cf_darkBlue5: #211f3c;
$cf_darkBlue6: #596ae7;
$cf_violet: #a87cff;

//Green
$green: #b4f57f;
$green2: #2dc07b;
$green4: #8fe9bf;
//rose
$rose: #f8c5e5;
$rose2: #f8e6c5;
$rose3: #ffcfa1f3;
/*gray colors*/
$cf_lightGray: #fafbfd;
$cf_gray1: #e0e0e0;
$cf_gray2: #bdbdbd;
$cf_gray3: #828282;
$cf_grey4: #999;
$cf_grey5: #aaa;
$cf_black1: #3f3d56;
$cf_black2: #585d74;
$cf_black3: #212529;

//black
$black: #000;
$black2: #00000040;
$black3: #00000080;
//gradients
$black3: #c3f1fa00;
$skyblue9: #c3f1faaf;

$yellowLight: #febd22;
$yellowLight2: #f8dc61;
$darkGray: #666;
$darkGray2: #7f7f7f3d;
$lightGray: #d2d2d2;
$lightBlue: #a7e6ee;
$lightGray2: #928f8f;
$green3: #37a337;
$lightRose: #f7cfed;
$darkGray3: #2e2d2d;
$gray5a: #5a5959;
$lightWhite: #e2e1e1;
$lightGray3: #868585;
$lightRose2: #eca4fa;
$lightGray4: #a09e9e;
$skyblue8: #39c0f5;
$skyblueLight: #62daf3;
$green5: #28be3f;
$lightBrown: #93626c;
$white7: #ebece9;
$skyblueLight: #62daf3;
$green5: #28be3f;
$lightBrown: #93626c;
$lightBlue2: #daf7fc;

// violet
$violet: #c837ab;
$violet2: #e6317c;
$cf_red8: #ff3c00;
$blue6: #4242ff;
$blue7: #4144dd;
$lightBlue2: #daf7fc;

/* List Colors */
$cf_gray4: #252a41;

$listColor1: #e1f2ff;
$listColor2: #e6e1ff;
$listColor3: #ffefe1;
$listColor4: #e1f2ff;
$listColor5: #f6f5f8;
$listColor6: #ffe3e3;
$listColor7: #d7ffdd;
$listColor8: #fff7c4;

$bisque: #fbd8d5;
$violetLight: #eae2fd;
$yellowLight3: #fcdb64;
$greenDark: #00bf8a;
$white8: #f1fafa;
$darkGray4: #9a9a9c;

$redBar: #F41634;
$yellowBar: #ffdd00;
$greenBar: #46CB5B;
$grayLight: #f5f5f5;
$skyBlue12: #36dbff;
$lightGray9: #d6d7d8;
$lightGray10: #f2f2f2;
$lightBlue3: #62daf4;
$darkRed: #cf1621;
$lightYellow: #ffebbd;
$lightGreen3: #aff5f1;
$lightRed: #faaabc;
$yellow6: #ffdd00;
$lightBlue4: #56ccf2;
$yellow7: #ffc107;
$darkGray5: #494855;
$lightGray11: #5c5c5c;
$yellowLight4: #fab570;
$lightPink: #ec9cad;
$lightGreen4: #85f0d1;
$lightBlue5: #9aa4ff;
$lightYellow2: #f7bf88;
$lightViolet: #cacff6;
$lightViolet2: #c5caf7;
$lightGray12: #c2c1c4;
$lightGray13: #c4c4c7;
$lightGray14: #c4c4c4;
$yellowLight8: #fcdfc2;

$lightGray5: #f2f2f2;
$lightskyblue: #bde0e7;
$lightGreen5: #b5f0e1;
$darkGray6: #495057;
$skyBlue13: #6eeae2;
$lightGreen6: #32ebb5;
$yellow8: #fcd532;
$lightGray15: #e8eaf1;
$lightGray16 : #dee2e6;
$transparent:rgba(33, 31, 60, 0.8);
$yellowGradient:linear-gradient(244.28deg , #FEE161 3.96%, #FFEFAA 49.64%, #FEE268 100%);

/*Heading Styles Typography (Ubuntu& Poppins) */
h1 {
  font-size: 36px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}
