.reportsKnowMore {
  border: 0px;
  color: $skyblueLight;
  background: $white;
  font-weight: bold;
  cursor: pointer;
}
.readMore {
  &__main {
    background-color: $white;
    border-radius: 5px;
    background-image: url('../assets/yelloGreenCircles.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    &__header {
      margin-top: 40px;
      margin-right: 40px;
      float: right;
    }
    &__dismissModal {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: $cf_blue;
      flex: none;
      margin: 10px 0px;
      border: 2px solid $cf_blue;
      padding: 2.2px 5.5px;
      border-radius: 5px;
      cursor: pointer;
    }

    &__knowMoreHeading {
      font-weight: 500;
      margin-top: 37px;
      margin-left: 40px;
      font-size: 24px;
      line-height: 28px;
      color: $cf_darkBlue5;
    }
    &__knowMoreDescription {
      margin: 16px 80px 72px 40px;
      text-align: justify;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.colorFor__redBar {
  background-color: $redBar !important;
}
.colorFor__greenBar {
  background-color: $greenBar !important;
}
.colorFor__yellowBar {
  background-color: $yellowBar !important;
}
